import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Hero from '../components/Service/Hero'
import Content from '../components/Service/Content'
import Intro from '../components/Service/Intro'
import FeatureCard from '../components/Card/Feature'
import CTA from '../components/Service/CTA'
import SEO from '../components/SEO'

class ServiceTemplate extends React.Component {

  render() {
    const { data, location } = this.props
    const post = data.allPrismicServices.edges[0].node.data
    const mobileFeature = data.mobileFeature.edges[0].node.data

    return (
      <Layout location={location}>
        <SEO
          title={post.heading && post.heading + ' Services'}
          type="article"
          description={post.lead || post.meta_description}
          location={location}
        />
        <div className="wrapper wrapper--y">

          {post.heading && (
            <h1 className="heading-3xl mb-8 lg:mb-6">
              {post.heading}
            </h1>
          )}

          <Hero
            lead={post.lead}
            body={post.body}
            listHeading={post.list_heading}
            list={post.list}
          />

          <Content content={post.content} />

          <h2 className="heading-xl mb-12">
            {post.heading} Services
          </h2>

          <Intro data={post} />

          {post.featured_case_study.document !== null && (
            <FeatureCard
              data={post}
              mobileFeature={mobileFeature}
              position="bottom"
              inset={true}
            />
          )}

        </div>

        <CTA offset={post.featured_case_study.document !== null} />
      </Layout >
    )
  }
}

ServiceTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export const postQuery = graphql`
  query Posts($uid: String!) {
    allPrismicServices(
      filter: { uid: { eq: $uid } }
      sort: { fields: last_publication_date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            heading
            icon {
              alt
              url
            }
            lead
            body {
              html
            }
            list_heading
            list {
              list_item
            }
            content {
              content_heading
              content_body {
                html
              }
            }
            services {
              service_item {
                document {
                  ... on PrismicServicesItems {
                    uid
                    data {
                      title
                      icon {
                        url
                        alt
                      }
                      category {
                        uid
                      }
                    }
                  }
                }
              }
            }
            meta_title
            meta_description
            featured_case_study {
              uid
              document {
                ... on PrismicCaseStudiesArticle {
                  data {
                    heading
                    hero {
                      alt
                      url
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 1317, maxHeight: 570) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            featured_case_study_text {
              text
              html
            }
          }
        }
      }
    }
    mobileFeature: allPrismicServices(
      filter: { uid: { eq: $uid } }
      sort: { fields: last_publication_date, order: DESC }
    ) {
      edges {
        node {
          data {
            featured_case_study {
              document {
                ... on PrismicCaseStudiesArticle {
                  data {
                    hero {
                      alt
                      copyright
                      url
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 640, maxHeight: 680) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ServiceTemplate
